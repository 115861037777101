import React from "react";
import {
  SineEase,
  EasingFunction,
  Vector3,
  SceneLoader,
  Animation,
  PhysicsImpostor,
  UniversalCamera,
  Texture,
  StandardMaterial,
  MeshBuilder,
  //   FreeCamera,
} from "@babylonjs/core";
import "@babylonjs/loaders/glTF";
import SceneComponent from "./SceneComponent";
import * as CANNON from "cannon";
// import { sleepEvent } from "cannon/src/objects/Body";

import { nfts } from "./nfts";
window.CANNON = CANNON;

const style = {
  width: "100%",
  height: "100%",
};

const ReactCanvas = (props) => {
  const createScene = function (scene) {
    //tracks if the user can double jump
    var doubleJump = 2;

    //retrieve the redenering canvas from the engine
    var canvas = scene.getEngine().getRenderingCanvas();
    // console.log(scene);

    //add some props to the scene
    scene.enablePhysics();
    //Set gravity for the scene (G force like, on Y-axis)
    scene.gravity = new Vector3(0, -0.1, 0);
    scene.collisionsEnabled = true;

    var camera = new UniversalCamera(
      "universal_camera_1",
      new Vector3(4, 4, -25),
      scene
    );
    camera.attachControl(canvas, true);
    camera._needMoveForGravity = true;
    camera.speed = 0.5;

    //Then apply collisions and gravity to the active camera
    camera.checkCollisions = true;
    camera.applyGravity = true;

    //Set the ellipsoid around the camera (e.g. your player's size)
    camera.ellipsoid = new Vector3(1.5, 1, 1.5);

    //add movement to w a s d
    camera.keysUp = [87]; // W
    camera.keysDown = [83]; // S
    camera.keysLeft = [65]; // A
    camera.keysRight = [68]; // D

    //elegant jump
    window.addEventListener("keydown", function (e) {
      console.log(doubleJump);
      console.log(camera.position.y);
      if (camera.position.y < 2) {
        doubleJump = 2;
      }
      if (e.keyCode === 32) {
        if (doubleJump > 0) {
          jump();
        }
        doubleJump -= 1;
      }
    });

    //actual jump animation
    function jump() {
      var animation = new Animation(
        "jump",
        "position.y",
        30,
        Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CYCLE
      );
      var keys = [
        {
          frame: 0,
          value: camera.position.y,
        },
        {
          frame: 30,
          value: camera.position.y + 2,
        },
      ];
      animation.setKeys(keys);

      var easingFunction = new SineEase();
      easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
      animation.setEasingFunction(easingFunction);

      camera.animations.push(animation);
      scene.beginAnimation(camera, 0, 30, false, 2);
    }

    var isLocked = false;

    // On click event, request pointer lock
    scene.onPointerDown = function (evt) {
      //true/false check if we're locked, faster than checking pointerlock on each single click.
      if (!isLocked) {
        canvas.requestPointerLock =
          canvas.requestPointerLock ||
          canvas.msRequestPointerLock ||
          canvas.mozRequestPointerLock ||
          canvas.webkitRequestPointerLock;
        if (canvas.requestPointerLock) {
          canvas.requestPointerLock();
        }
      }
    };

    // Event listener when the pointerlock is updated (or removed by pressing ESC for example).
    var pointerlockchange = function () {
      var controlEnabled =
        document.mozPointerLockElement ||
        document.webkitPointerLockElement ||
        document.msPointerLockElement ||
        document.pointerLockElement ||
        null;

      // If the user is already locked
      if (!controlEnabled) {
        //camera.detachControl(canvas);
        isLocked = false;
      } else {
        //camera.attachControl(canvas);
        isLocked = true;
      }
    };

    // Attach events to the document
    document.addEventListener("pointerlockchange", pointerlockchange, false);
    document.addEventListener("mspointerlockchange", pointerlockchange, false);
    document.addEventListener("mozpointerlockchange", pointerlockchange, false);
    document.addEventListener(
      "webkitpointerlockchange",
      pointerlockchange,
      false
    );

    // create a new mesh and replace placeholder
    const options = {
      width: 2,
      height: 2,
      // depth: 0.00000000037252903,
    };

    nfts.map((nft) => {
      const newNft = MeshBuilder.CreatePlane(`${nft.name}`, options, scene);
      const material = new StandardMaterial(`${nft.name}`);
      const texture = new Texture(nft.url, scene);
      material.emissiveTexture = texture;
      newNft.material = material;
      newNft.position = new Vector3(nft.x, nft.y, nft.z);
      return nft;
    });

    //load the gltf court
    SceneLoader.Append("../TheCourt/", "thecourt.gltf", scene, function () {
      for (var i = 0; i < scene.meshes.length; i++) {
        if (scene.meshes[i].name.includes("invisible_collision")) {
          scene.meshes[i].isVisible = false;
          scene.meshes[i].checkCollisions = true;
          scene.meshes[i].physicsImpostor = new PhysicsImpostor(
            scene.meshes[i],
            PhysicsImpostor.BoxImpostor,
            { mass: 0, restitution: 1, friction: 0.2 },
            scene
          );
        }
        if (
          scene.meshes[i].name === "placeholder.001" ||
          scene.meshes[i].name === "placeholder.002" ||
          scene.meshes[i].name === "placeholder.003" ||
          scene.meshes[i].name === "placeholder.004" ||
          scene.meshes[i].name === "placeholder.005"
        ) {
          scene.meshes[i].isVisible = false;
        }
      }
    });
  };

  return <SceneComponent onSceneReady={createScene} style={style} />;
};
export default ReactCanvas;
