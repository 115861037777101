import './App.css';
import ReactCanvasCourt from './components/reactCanvasCourt'

function CourtApp() {
  return (
    <div className='App'>
      <header className="App-header">
        <ReactCanvasCourt />
      </header>
    </div>
  );
}

export default CourtApp;