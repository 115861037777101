import React from "react";

const ModalComponent = ({ currentMesh, showComp, toggle }) => {
  return (
    <div
      style={{
        display: showComp === false ? "none" : "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 999,
        // background: "transparent",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <button
        style={{ float: "right", padding: "0.3rem", cursor: "pointer" }}
        onClick={toggle}
      >
        Close Modal
      </button>

      <h6 style={{ float: "left" }}>
        Some informations about the mesh{" "}
        {currentMesh !== null ? currentMesh.name : " "}
      </h6>
    </div>
  );
};

export default ModalComponent;
