import "./App.css";
import ReactCanvasCourt from "./components/reactCanvasCourt2";

function CourtApp() {
  return (
    <div className="App">
      <header className="App-header">
        <ReactCanvasCourt />
      </header>
    </div>
  );
}

export default CourtApp;
