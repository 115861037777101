import React, { useState } from "react";
import {
  SineEase,
  EasingFunction,
  Vector3,
  SceneLoader,
  ExecuteCodeAction,
  Animation,
  PhysicsImpostor,
  UniversalCamera,
  ActionManager,
  Ray,
} from "@babylonjs/core";
import "@babylonjs/loaders/glTF";
import SceneComponent from "./SceneComponent";
import ModalComponent from "./ModalComponent";
import * as CANNON from "cannon";
window.CANNON = CANNON;

const style = {
  width: "100%",
  height: "100%",
};

const ReactCanvas = (props) => {
  const [currentMesh, setCurrentMesh] = useState(null);
  const [showComp, setShowComp] = useState(false);
  const toggle = () => {
    setShowComp(!showComp);
  };

  const createScene = function (scene) {
    //tracks if the user can double jump
    var doubleJump = 2;

    //retrieve the redenering canvas from the engine
    var canvas = scene.getEngine().getRenderingCanvas();

    //add some props to the scene
    scene.enablePhysics();
    //Set gravity for the scene (G force like, on Y-axis)
    scene.gravity = new Vector3(0, -0.1, 0);
    scene.collisionsEnabled = true;

    var camera = new UniversalCamera(
      "universal_camera_1",
      new Vector3(4, 4, -25),
      scene
    );
    camera.attachControl(canvas, true);
    camera._needMoveForGravity = true;
    camera.speed = 0.5;

    //Then apply collisions and gravity to the active camera
    camera.checkCollisions = true;
    // camera.applyGravity = true;

    //Set the ellipsoid around the camera (e.g. your player's size)
    camera.ellipsoid = new Vector3(1.5, 1.5, 1.5);

    //add movement to w a s d
    camera.keysUp = [87]; // W
    camera.keysDown = [83]; // S
    camera.keysLeft = [65]; // A
    camera.keysRight = [68]; // D

    // const rayTracer = camera.getForwardRay(0.5);

    //elegant jump
    window.addEventListener("keydown", function (e) {
      if (camera.position.y < 2.5) {
        doubleJump = 2;
      }
      if (e.key === "space") {
        if (doubleJump > 0) {
          jump();
        }
        doubleJump -= 1;
      }
    });

    //actual jump animation
    function jump() {
      var animation = new Animation(
        "jump",
        "position.y",
        30,
        Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CYCLE
      );
      var keys = [
        {
          frame: 0,
          value: camera.position.y,
        },
        {
          frame: 30,
          value: camera.position.y + 2,
        },
      ];
      animation.setKeys(keys);

      var easingFunction = new SineEase();
      easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
      animation.setEasingFunction(easingFunction);

      camera.animations.push(animation);
      scene.beginAnimation(camera, 0, 30, false, 2);
    }

    var isLocked = false;

    // On click event, request pointer lock
    scene.onPointerDown = function (evt) {
      //true/false check if we're locked, faster than checking pointerlock on each single click.
      if (!isLocked) {
        canvas.requestPointerLock =
          canvas.requestPointerLock ||
          canvas.msRequestPointerLock ||
          canvas.mozRequestPointerLock ||
          canvas.webkitRequestPointerLock;
        if (canvas.requestPointerLock) {
          canvas.requestPointerLock();
        }
      }
    };

    // Event listener when the pointerlock is updated (or removed by pressing ESC for example).
    var pointerlockchange = function () {
      var controlEnabled =
        document.mozPointerLockElement ||
        document.webkitPointerLockElement ||
        document.msPointerLockElement ||
        document.pointerLockElement ||
        null;

      // If the user is already locked
      if (!controlEnabled) {
        //camera.detachControl(canvas);
        isLocked = false;
      } else {
        //camera.attachControl(canvas);
        isLocked = true;
      }
    };

    // Attach events to the document
    document.addEventListener("pointerlockchange", pointerlockchange, false);
    document.addEventListener("mspointerlockchange", pointerlockchange, false);
    document.addEventListener("mozpointerlockchange", pointerlockchange, false);
    document.addEventListener(
      "webkitpointerlockchange",
      pointerlockchange,
      false
    );
    window.addEventListener("pointerlockchange", pointerlockchange, false);

    //if character falls under the map respawn it
    scene.onBeforeRenderObservable.add(() => {
      if (camera.position.y < 0) {
        camera.position.set(1.5, 1.5, 1.5);
      }
    });

    //load the gltf court
    SceneLoader.Append(
      "../Dystopia/",
      "metamuseum_v3.gltf",
      scene,
      function () {
        scene.createDefaultLight(true);
        //     for (var i = 0; i < scene.meshes.length; i++) {
        //       if (scene.meshes[i].name.includes("invisible_collision")) {
        //         scene.meshes[i].isVisible = false;
        //         scene.meshes[i].checkCollisions = true;
        //         scene.meshes[i].physicsImpostor = new PhysicsImpostor(
        //           scene.meshes[i],
        //           PhysicsImpostor.BoxImpostor,
        //           { mass: 0, restitution: 1, friction: 0.2 },
        //           scene
        //         );
        //       }
        //   if (scene.meshes[i].name.includes("placeholder")) {
        //     //add an actionManager
        //     scene.meshes[i].actionManager = new ActionManager(scene);
        //     //calculate the distance between mesh and user
        //     var distanceSquared =
        //       Math.pow(scene.meshes[i].position.x - camera.ellipsoid.x, 2) +
        //       Math.pow(scene.meshes[i].position.y - camera.ellipsoid.y, 2);
        //     var distance = Math.sqrt(distanceSquared);
        //     //add a trigger for the action event
        //     let placeholderAction = new ExecuteCodeAction(
        //       ActionManager.OnPointerOverTrigger,
        //       function () {
        //         console.log("hover");
        //       }
        //     );
        //     //register the action
        //     scene.meshes[i].actionManager.registerAction(placeholderAction);
        //   }
        // }
      }
    );
    function vecToLocal(vector, mesh) {
      const m = mesh.getWorldMatrix();
      const v = Vector3.TransformCoordinates(vector, m);
      return v;
    }

    function castRay() {
      const origin = camera.position;
      let forward = new Vector3(0, 0, 1);
      forward = vecToLocal(forward, camera);

      let direction = forward.subtract(origin);
      direction = Vector3.Normalize(direction);
      const length = 15;
      const ray = new Ray(origin, direction, length);

      const predicate = (mesh) => {
        if (mesh.name.includes("picture")) {
          return true;
        }
        return false;
      };

      const hit = scene.pickWithRay(ray, predicate);

      if (hit.pickedMesh) {
        window.addEventListener("keydown", (e) => {
          if (e.key === "e" && hit.pickedMesh) {
            setCurrentMesh(hit.pickedMesh);
            toggle();
            document.exitPointerLock();
            console.log("something");
          } else {
            hit.pickedMesh = false;
          }
        });
      }
    }

    scene.registerBeforeRender(function () {
      castRay();
    });

    return scene;
  };
  return (
    <div style={style}>
      <ModalComponent
        currentMesh={currentMesh}
        showComp={showComp}
        toggle={toggle}
      />
      <SceneComponent onSceneReady={createScene} style={style} />
    </div>
  );
};

export default ReactCanvas;
