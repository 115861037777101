import './App.css';
import ReactCanvas from './components/reactCanvasAztec'

function AztecApp() {
  return (
    <div className='App'>
      <header className="App-header">
        <ReactCanvas />
      </header>
    </div>
  );
}

export default AztecApp;