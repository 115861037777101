import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AztecApp from "./AztecApp";
import CourtApp from "./CourtApp";
import CourtApp2 from "./CourtApp2";
import DystopiaApp from "./DystopiaApp";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<CourtApp />} />
      <Route path="aztec" element={<AztecApp />} />
      <Route path="thecourt" element={<CourtApp />} />
      <Route path="thecourt2" element={<CourtApp2 />} />
      <Route path="dystopia" element={<DystopiaApp />} />
    </Routes>
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
