import "./App.css";
import ReactCanvas from "./components/ReactCanvasDystopia";

function DystopiaApp() {
  return (
    <div className="App">
      <header className="App-header">
        <ReactCanvas />
      </header>
    </div>
  );
}

export default DystopiaApp;
