export const nfts = [
  {
    id: 1,
    name: "nft.1",
    url: "https://lh3.googleusercontent.com/Vzrsj68OQXJXxr1Zev0JIRZxWioU4MIIjMmMcp9MK8WdrmSyej-3mcg7kBgLGEM96TWOvwZ6D8stHPJCKkwa2Tl4wN8I6Ay_KAnJ1A=w600",
    x: -3.809650421142578,
    y: 3.2465648651123047,
    z: 4.429416179656982,
  },
  {
    id: 2,
    name: "nft.2",
    url: "https://lh3.googleusercontent.com/oXt4dmnH9ymPMgM5TT9GtniYPrgVUOg-QYRNZ-Pk0Hdaf9sSgrols3PGyt1m9iHHyUFlDniEeJRo-xMAycsA84JB4qyXfCO6em4i=w600",
    x: -11.308574676513672,
    y: 3.2465648651123047,
    z: 4.429416179656982,
  },
  {
    id: 3,
    name: "nft.3",
    url: "https://lh3.googleusercontent.com/wuvT-EaURNt5vMFQZR6YCckL4PpDzIykQ4uTyrXwTiLj6eeDFXF2NB75XVFar9oUOeC8WLHPLKXUpDTDtTqnTJa0sC7P61W6wyQz1Q=w600",
    x: 11.18006706237793,
    y: 3.2465648651123047,
    z: 4.429416179656982,
  },
  {
    id: 4,
    name: "nft.4",
    url: "https://lh3.googleusercontent.com/xz04Wf2HBM5mjv0_QQCEwkIOQeQ2N3QzmMauPTGCKNtqv7I_hatZUnsygtKeGZo8-AUvuDpNRDvO_K6QJy1nqm2xQHf9WdPnLSLo=w600",
    x: 18.658443450927734,
    y: 3.2465648651123047,
    z: 4.429416179656982,
  },
  {
    id: 5,
    name: "nft.5",
    url: "https://lh3.googleusercontent.com/ocgEIK6DDEizfBsPGCcDxgLnRPqrGWu5M-y-kAjm2fM4X0BERxckQIhuZn6aJlW69TYjd_I3TWzUQwrP6UVPxCR5ZSMfzzYsi8dtoA=w600",
    x: 18.658443450927734,
    y: 11.37382984161377,
    z: 4.429416179656982,
  },
];
